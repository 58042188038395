<template>
  <div class="analysis-list">
    <div class="head">
      <!-- <van-grid :border="false" :column-num="1">
        <van-grid-item>
          <van-image :src="headImg" width="12rem" height="12rem" fit="contain" />
        </van-grid-item>
      </van-grid>-->
      <van-row @click="intradayAdd" type="flex" justify="space-around">
        <van-col span="24">
          <span class="text">电子支付金额</span>
          <div class="money">{{ data.allMoney }}</div>
        </van-col>
        <van-col span="12">
          <span class="text">安装辅材金额</span>
          <div class="number">{{ data.azfzje }}</div>
        </van-col>
        <van-col span="12">
          <span class="text">维修收款金额</span>
          <div class="number">{{ data.wxskje }}</div>
        </van-col>
        <van-col span="12">
          <span class="text">安装辅材工单数</span>
          <div class="number">{{ data.azfzgds }}</div>
        </van-col>
        <van-col span="12">
          <span class="text">维修收款工单数</span>
          <div class="number">{{ data.wxskgds }}</div>
        </van-col>
        <van-col span="24">
          <div class="hline"></div>
        </van-col>
        <van-col span="11">
          <span class="text">当天新增安装工单量</span>
          <div class="number">{{ data.dtxzangdl }}</div>
        </van-col>
        <van-col span="1">
          <div class="line"></div>
        </van-col>
        <van-col span="11">
          <span class="text">当天新增维修工单量</span>
          <div class="number">{{ data.dtxzwxgdl }}</div>
        </van-col>
        <van-col span="11">
          <span class="text">当天新增安装设备数</span>
          <div class="number">{{ data.dtxzazsbs }}</div>
        </van-col>
        <van-col span="1">
          <div class="line"></div>
        </van-col>
        <van-col span="11">
          <span class="text">当天新增维修设备数</span>
          <div class="number">{{ data.dtxzwxsbs }}</div>
        </van-col>
      </van-row>
    </div>
    <div class="second" @click="$router.push('/masterFinished/index')">
      <van-row type="flex" justify="space-around">
        <van-col span="11">
          <span class="text">师傅报完工工单数一安装</span>
          <div class="number">{{ data.bwgazsl }}</div>
        </van-col>
        <van-col span="1">
          <div class="line"></div>
        </van-col>
        <van-col span="11">
          <span class="text">师傅报完工工单数一维修</span>
          <div class="number">{{ data.bwgwxsl }}</div>
        </van-col>
        <van-col span="11">
          <span class="text">设备台数</span>
          <div class="number">{{ data.bwgazts }}</div>
        </van-col>
        <van-col span="1">
          <div class="line"></div>
        </van-col>
        <van-col span="11">
          <span class="text">设备台数</span>
          <div class="number">{{ data.bwgwxts }}</div>
        </van-col>
      </van-row>
    </div>
    <div class="third" @click="$router.push('/reservation/index')">
      <van-row type="flex" justify="space-around">
        <van-col span="11">
          <span class="text">预约今天—安装工单</span>
          <div class="number">{{ data.yyjtazgd }}</div>
          <span class="text">预约今天—维修工单</span>
          <div class="number">{{ data.yyjtwxgd }}</div>
        </van-col>
        <van-col span="1">
          <div class="line"></div>
        </van-col>
        <van-col span="11">
          <span class="text">预约明天—安装工单</span>
          <div class="number">{{ data.yymtazgd }}</div>
          <span class="text">预约明天—维修工单</span>
          <div class="number">{{ data.yymtwxgd }}</div>
        </van-col>
      </van-row>
    </div>
    <!-- <div class="third">
      <van-row type="flex" justify="space-around">
        <van-col span="11">
          <span class="text">当月分销金额</span>
          <div class="number">{{data.yyjtazgd}}</div>
          <span class="text">当月分销台数</span>
          <div class="number">{{data.yyjtwxgd}}</div>
        </van-col>
        <van-col span="1">
          <div class="line"></div>
        </van-col>
        <van-col span="11">
          <span class="text">当天分销金额</span>
          <div class="number">{{data.yymtazgd}}</div>
          <span class="text">当天分销台数</span>
          <div class="number">{{data.yymtwxgd}}</div>
        </van-col>
      </van-row>
    </div>-->
    <div class="main">
      <van-cell-group v-for="(analysis, index) in analysisList" title :key="index">
        <van-cell
          :title="analysis.moduleName"
          :to="'/analysis/' + analysis.moduleId"
          style="margin-bottom: 15px;"
          @click="handleEvent(analysis.moduleName)"
        >
          <template #icon>
            <van-image :src="analysisIcons[analysis.moduleId]" width="2rem" height="2rem" style="margin-right: 5px;" />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { getUrlKey } from '@/utils/common'
export default {
  name: 'Analysis',
  data() {
    return {
      headImg: require('../assets/home/head_logo.png'),
      analysisList: [],
      analysisIcons: {
        1001: require('../assets/icon/icon_1001.png'),
        1002: require('../assets/icon/icon_1002.png'),
        1003: require('../assets/icon/icon_1003.png'),
        1004: require('../assets/icon/icon_1004.png'),
        1005: require('../assets/icon/icon_1005.png'),
        1006: require('../assets/icon/icon_1006.png'),
        1007: require('../assets/icon/icon_1007.png'),
        1008: require('../assets/icon/icon_1008.png'),
        1009: require('../assets/icon/icon_1009.png'),
        1010: require('../assets/icon/icon_1010.png'),
        1011: require('../assets/icon/icon_1011.png'),
        1012: require('../assets/icon/icon_1012.png'),
        1013: require('../assets/icon/icon_1013.png')
      },
      data: {
        allMoney: 0,
        azfzje: 0,
        azfzgds: 0,
        wxskje: 0,
        wxskgds: 0,
        dtxzangdl: 0,
        dtxzwxgdl: 0,
        dtxzazsbs: 0,
        dtxzwxsbs: 0,
        bwgazsl: 0,
        bwgazts: 0,
        bwgwxsl: 0,
        bwgwxts: 0,
        yyjtazgd: 0,
        yymtazgd: 0,
        yyjtwxgd: 0,
        yymtwxgd: 0
      }
    }
  },
  methods: {
    //格式化金额
    formatMoney(money, decimals = 2) {
      return new Intl.NumberFormat('zh-CN', {
        style: 'currency', // 货币形式
        currency: 'CNY', // "CNY"是人民币
        currencyDisplay: 'symbol', // 默认“symbol”，中文中代表“¥”符号
        // useGrouping: true, // 是否使用分组分隔符，如千位分隔符或千/万/亿分隔符，默认为true
        // minimumIntegerDigits: 1, // 使用的整数数字的最小数目.可能的值是从1到21,默认值是1
        // minimumFractionDigits: 2, // 使用的小数位数的最小数目.可能的值是从 0 到 20
        maximumFractionDigits: decimals // 使用的小数位数的最大数目。可能的值是从 0 到 20
      }).format(money)
    },

    //
    intradayAdd() {
      this.$router.push('/analysis/intradayAdd')
    },
    //数据
    getData() {
      let params = {
        openid: getUrlKey('openid', window.location.href),
        moduleId: '11',
        type: '家用'
      }
      this.postRequest('/wechat-analysis/today-summary', params).then(res => {
        let arr = res.data
        //电子支付金额
        // this.data.allMoney = arr[2].c1 + arr[3].c1;
        this.data.allMoney = this.formatMoney(arr[2].c1 + arr[3].c1)
        //安装辅材金额、工单数
        // this.data.azfzje = arr[2].c1;
        this.data.azfzje = this.formatMoney(arr[2].c1)
        this.data.azfzgds = arr[2].c2
        // this.data.wxskje = arr[3].c1;
        this.data.wxskje = this.formatMoney(arr[3].c1)
        this.data.wxskgds = arr[3].c2
        //当天新增安装工单量
        this.data.dtxzangdl = arr[4].c1
        this.data.dtxzwxgdl = arr[5].c1
        this.data.dtxzazsbs = arr[4].c2
        this.data.dtxzwxsbs = arr[5].c2
        this.data.bwgazsl = arr[0].c1
        this.data.bwgazts = arr[0].c2
        this.data.bwgwxsl = arr[1].c1
        this.data.bwgwxts = arr[1].c2
        this.data.yyjtazgd = arr[6].c1
        this.data.yymtazgd = arr[8].c1
        this.data.yyjtwxgd = arr[7].c1
        this.data.yymtwxgd = arr[9].c1
      })
    },
    handleEvent(title) {
      window.sessionStorage.setItem('moduleTitle', title)
    }
  },
  created() {
    this.analysisList = JSON.parse(window.sessionStorage.getItem('analysisList'))
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 1px;
  height: 100%;
  background-color: #c1c1c1;
}
.hline {
  height: 1px;
  background-color: #c1c1c1;
  margin-bottom: 6px;
}
.analysis-list .head {
  margin-bottom: 10px;
}
.head {
  background-color: #fff;
  padding: 20px 20px 20px 16px;
  .text {
    font-size: 14px;
  }
  .money {
    color: #428dff;
    font-size: 28px;
    font-weight: 700;
    padding: 10px 0 15px 0;
  }
  .number {
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0 8px 0;
  }
}
.second {
  background-color: #fff;
  padding: 20px 16px 10px 16px;
  margin-bottom: 10px;
  .text {
    font-size: 14px;
  }
  .number1 {
    color: #428dff;
    font-size: 28px;
    font-weight: 700;
    padding: 10px 0 15px 0;
  }
  .number {
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0 15px 0;
  }
}
.third {
  background-color: #fff;
  padding: 20px 16px 10px 16px;
  margin-bottom: 10px;
  .text {
    font-size: 14px;
  }
  .number {
    // color: #428dff;
    // font-size: 28px;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0 15px 0;
  }
}
</style>
